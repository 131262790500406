import config from '../config';
import { makeQueryPath } from './routeUtils';
import {
    clearEagleIdVerifier,
    getEagleIdState,
    getEagleIdVerifier,
    IOAuthToken,
    setEagleIdState,
    setEagleIdVerifier,
} from './storageUtils';
import * as routeUrls from '../components/Routes/routeUrls';
import { getPkceInfo, PkceInfo } from './pkceUtils';
import { clearStorage } from './storageUtils';
import { getEagleIdAuthURL, getEagleIdTokenURL, getEagleIdVerifyURL, getLogoutURL } from './urlUtils';

// Implementation from common-fe
const COOKIE_DEVICE_ID_NAME = 'screening_eagle_device_id';
export const getCookie = (name: string) => {
    const deviceIdCookie = document.cookie.split(';').find((cookie) => cookie.split('=')[0].trim() === name);
    return deviceIdCookie ? deviceIdCookie.split('=')[1] : undefined;
};
export const getDeviceID = (): string => {
    let deviceID = getCookie(COOKIE_DEVICE_ID_NAME);
    if (!deviceID) {
        deviceID = self.crypto.randomUUID();
        if (location.hostname === 'localhost') {
            // not possible to set cookie for another domain, this will default to localhost
            document.cookie = `${COOKIE_DEVICE_ID_NAME}=${deviceID};path=/`;
        } else {
            document.cookie = `${COOKIE_DEVICE_ID_NAME}=${deviceID};domain=screeningeagle.com;path=/`;
        }
    }
    return deviceID;
};

/** Generates an ID for CSRF checking */
export const simpleID = (length: number) => {
    return new Array(length)
        .fill('0')
        .join('')
        .replace(/[0]/gi, () =>
            // eslint-disable-next-line no-bitwise
            (crypto.getRandomValues(new Uint8Array(1))[0] & 0xf).toString(16)
        );
};

// Logging in using Eagle ID
export async function getEagleIdAuthParams() {
    const randomState = simpleID(6);
    setEagleIdState(randomState);

    const pkceInfo: PkceInfo = await getPkceInfo();
    setEagleIdVerifier(pkceInfo.code_verifier);

    const API_SCOPE = ['openid', 'email', 'profile'].join(' ');

    return {
        response_type: 'code',
        client_id: config.CLIENT_ID,
        redirect_uri: `${location.origin}${routeUrls.AUTH}`,
        state: randomState,
        scope: API_SCOPE,
        product: config.PRODUCT,
        client: config.CLIENT,
        version: config.APP_VERSION,
        code_challenge: pkceInfo.code_challenge,
        code_challenge_method: pkceInfo.code_challenge_method,
        'X-SE-Client-Id': getDeviceID(),
    };
}

export async function redirectEagleIdAuth() {
    const url = makeQueryPath(getEagleIdAuthURL(), await getEagleIdAuthParams());
    window.open(url, '_self');
}

export function validateCsrf(state: string) {
    return state === getEagleIdState();
}

// Getting token from Eagle ID
export function getEagleIdTokenParams(code: string, verifier: string) {
    return {
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: `${location.origin}${routeUrls.AUTH}`,
        code_verifier: verifier,
    };
}

export function getEagleIdTokenHeaders() {
    const auth = btoa(`${config.CLIENT_ID}:${config.CLIENT_SECRET}`);
    return new Headers([
        ['Authorization', `Basic ${auth}`],
        ['Content-Type', 'application/x-www-form-urlencoded'],
    ]);
}

export async function getTokenFromCode(code: string) {
    const options = {
        method: 'POST',
        headers: getEagleIdTokenHeaders(),
    };

    const verifier = getEagleIdVerifier();
    clearEagleIdVerifier();
    const url = makeQueryPath(getEagleIdTokenURL(), getEagleIdTokenParams(code, verifier));

    const response = await fetch(url, options);
    return response;
}

// Verifying token
export async function verifyToken(token: IOAuthToken) {
    const options = {
        method: 'POST',
        headers: new Headers([
            ['Content-Type', 'application/json'],
            ['Authorization', `${token.token_type} ${token.access_token}`],
        ]),
        body: '{}',
    };
    const url = getEagleIdVerifyURL();

    const response = await fetch(url, options);
    return response.ok;
}

// Implementation from common-fe
// Reference: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
// Reference https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
export const parseJwt = (token: string) => {
    const base64Str = token.split('.')[1];
    const base64 = base64Str.replace(/-/g, '+').replace(/_/g, '/');
    const binString = atob(base64);
    const jsonPayload = new TextDecoder().decode(Uint8Array.from(binString, (s) => s.codePointAt(0) ?? 0));
    const result = JSON.parse(jsonPayload);
    return result;
};

export interface IUserDetail {
    name: string;
    email: string;
}

export function getUserDetailsFromToken(response: IOAuthToken): IUserDetail {
    if (!response.id_token) {
        logout();
    }
    const { name, email } = parseJwt(response.id_token);
    return { name, email };
}

export function getLogoutParams() {
    return {
        client_id: config.CLIENT_ID,
        state: simpleID(6),
        redirect_uri: `${location.origin}${routeUrls.HOME}`,
    };
}

export function redirectLogout() {
    const url = makeQueryPath(getLogoutURL(), getLogoutParams());
    window.open(url, '_self');
}

export function logout() {
    clearStorage();
    redirectLogout();
}
