export interface IOAuthToken {
    access_token: string;
    expires_in: number;
    expires?: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
    error?: string;
    error_description?: string;
}

export function saveTokenToStorage(token: IOAuthToken) {
    token.expires = Math.floor(Date.now() / 1000);
    sessionStorage.setItem('OAuthToken', JSON.stringify(token));
}

export function getTokenFromStorage(): IOAuthToken | null {
    const token = sessionStorage.getItem('OAuthToken');
    if (token) {
        return JSON.parse(token);
    }
    return null;
}

export function setEagleIdState(state: string) {
    sessionStorage.setItem('eagleIDState', state);
}

export function getEagleIdState() {
    return sessionStorage.getItem('eagleIDState') || '';
}

export function setEagleIdVerifier(verifier: string) {
    sessionStorage.setItem('eagleIDCodeVerifier', verifier);
}

export function getEagleIdVerifier() {
    return sessionStorage.getItem('eagleIDCodeVerifier') || '';
}

export function clearEagleIdVerifier() {
    sessionStorage.removeItem('eagleIDCodeVerifier');
}

export function clearStorage() {
    sessionStorage.clear();
}
