export type PkceInfo = {
    code_verifier: string;
    code_challenge: string;
    code_challenge_method: string;
};

const uuid = () => self.crypto.randomUUID();

/**
 * A special case of Base64 that replaces '+' with '-', '/' with '_', and removes '=' padding at the end.
 */
export function base64UrlEncode(s: string) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

// Reference: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function generateChallenge(value: string) {
    const hashBuffer = await self.crypto.subtle.digest('SHA-256', new TextEncoder().encode(value));
    const hashArray = Array.from(new Uint8Array(hashBuffer)).map((byte) => String.fromCharCode(byte));
    return base64UrlEncode(hashArray.join(''));
}

// Implementation from common-fe
export async function getPkceInfo(): Promise<PkceInfo> {
    const codeVerifier = `${uuid()}-${uuid()}`;
    const codeChallenge = await generateChallenge(codeVerifier);
    return {
        code_verifier: codeVerifier,
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
    };
}
