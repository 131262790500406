import React, { useEffect } from 'react';
import { Layout, Menu, theme, MenuProps } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
import PrototypeListing from '../PrototypeListing';
import { logout } from '../../utils/authUtils';
import { menuItems } from './menuItems';
import { track } from '../../utils/analyticsUtils';

const LandingPage: React.FunctionComponent = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const handleLogout = () => {
        logout();
    };

    const onMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key == 'Logout') {
            handleLogout();
        }
    };

    useEffect(() => {
        track('Landing Page');
    }, []);

    return (
        <Layout hasSider>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <div className="demo-logo-vertical" />
                <Menu theme="dark" mode="inline" items={menuItems} onClick={onMenuClick} />
            </Sider>
            <Layout style={{ marginLeft: 200 }}>
                <Header style={{ padding: 0, background: colorBgContainer }} />
                <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                    <div
                        style={{
                            padding: 24,
                            textAlign: 'center',
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <PrototypeListing />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Dream Labs ©{new Date().getFullYear()} Created by Screening Eagle Technologies
                </Footer>
            </Layout>
        </Layout>
    );
};

export default LandingPage;
