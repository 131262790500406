import React, { useEffect, useRef, useState } from 'react';
import { getTokenFromCode, logout, redirectEagleIdAuth, validateCsrf } from '../../utils/authUtils';
import { parseQuery } from '../../utils/routeUtils';
import { useNavigate } from 'react-router-dom';
import * as routeUrls from '../Routes/routeUrls';
import { IOAuthToken, saveTokenToStorage } from '../../utils/storageUtils';
import Spinner from '../shared/Spinner';
import ErrorPage, { ErrorText } from './ErrorPage';

const Auth: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const isFirstTime = useRef(true);
    const [loadingText, setLoadingText] = useState('Loading...');
    const [errorText, setErrorText] = useState<undefined | ErrorText>(undefined);

    const handleError = (e: Error) => {
        setErrorText({
            title: 'An error occurred',
            description: e.toString(),
        });
    };

    const {
        code,
        state,
        error,
        error_description: errorDescription,
    } = parseQuery<{
        code: string;
        lang: string;
        state: string;
        error: string;
        error_description: string;
    }>(location.search);

    useEffect(() => {
        // We only want to run this once
        if (!isFirstTime.current) return;

        if (code && state) {
            setLoadingText('Signing in...');
            const isValid = validateCsrf(state);
            if (isValid) {
                getTokenFromCode(code)
                    .then((response) => response.json())
                    .then((token: IOAuthToken) => {
                        if (token.error) {
                            throw new Error(`[${token.error}] ${token.error_description}`);
                        }
                        saveTokenToStorage(token);
                    })
                    .then(() => navigate(routeUrls.HOME))
                    .catch(handleError);
                return;
            }
        } else if (error) {
            setErrorText({
                title: 'Failed to login',
                description: `[${error}] ${errorDescription}`,
            });
            return;
        }
        setLoadingText('Redirecting...');
        redirectEagleIdAuth();
    }, [navigate, code, state, error, errorDescription]);

    useEffect(() => {
        isFirstTime.current = false;
    }, []);

    return errorText ? (
        <ErrorPage errorText={errorText} buttonText="Try again" onClick={() => logout()} />
    ) : (
        <Spinner tip={loadingText} />
    );
};

export default Auth;
