import React from 'react';

import { List } from 'antd';

import PrototypeCard from './PrototypeCard';

import entries from '../../entries.json';

export type Defaults = {
    enabled: boolean;
    hidden: boolean;
};

export type Entry = {
    id: string;
    title: string;
    desc: string;
    icon: string;
    url: string;
    defaults: Defaults;
};

const PrototypeListing: React.FunctionComponent = () => {
    return (
        <List
            grid={{ gutter: 16, column: 3 }}
            pagination={{ pageSize: 3 }}
            dataSource={entries}
            renderItem={(entry) => (
                <List.Item key={`li-${entry.id}`}>
                    <PrototypeCard
                        id={entry.id}
                        key={`pc-${entry.id}`}
                        title={entry.title}
                        desc={entry.desc}
                        icon={entry.icon}
                        url={entry.url}
                        defaults={entry.defaults}
                    />
                </List.Item>
            )}
        />
    );
};

export default PrototypeListing;
