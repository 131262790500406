const config = {
    APP_ENV: process.env.REACT_APP_ENV,

    APP_VERSION: process.env.REACT_APP_VERSION,
    PRODUCT: process.env.REACT_APP_PRODUCT,
    CLIENT: process.env.CLIENT,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,

    EAGLE_ID_DOMAIN: process.env.REACT_APP_EAGLE_ID_DOMAIN,
};

export default config;
