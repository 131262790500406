import { sha256 } from 'js-sha256';
import { getUserDetailsFromToken } from './authUtils';
import { getTokenFromStorage } from './storageUtils';

export interface IAnalyticsDetail {
    uuid: string;
    email_domain: string;
}

export function getUserAnalyticsDetails(): IAnalyticsDetail {
    const storedToken = getTokenFromStorage();

    if (storedToken) {
        const user = getUserDetailsFromToken(storedToken);
        const domain = user.email.toString().split('@')[1];
        const uuid = sha256(user.email);

        return { uuid: uuid, email_domain: domain };
    }

    // This should not happen
    return { uuid: 'Unknown User', email_domain: 'Unknown Domain' };
}

export function track(event: string, data?: Record<string, any>): void {
    const umami = window.umami;
    if (process.env.NODE_ENV === 'production' && umami) {
        const userDetails = getUserAnalyticsDetails();

        umami.identify({ ...userDetails, event: event, ...data }); // Session tracking
        umami.track(event, { ...userDetails, ...data }); // Event tracking
        umami.track(); // Page view tracking
    }
}
