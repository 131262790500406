import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as routeUrls from './routeUrls';

const NotFound: React.FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <div className="error-page">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button type="primary" onClick={() => navigate(routeUrls.HOME)}>
                        Back Home
                    </Button>
                }
            />
        </div>
    );
};

export default NotFound;
