import React, { useState } from 'react';

import { Card, Modal } from 'antd';
import { getTokenFromStorage } from '../../utils/storageUtils';
const { Meta } = Card;

import { ShrinkOutlined } from '@ant-design/icons';

import { useFlags, useFlagsmith } from 'flagsmith/react';
import { getUserAnalyticsDetails, track } from '../../utils/analyticsUtils';

interface DefaultProps {
    enabled: boolean;
    hidden: boolean;
}

interface EntryProps {
    id: string;
    title: string;
    desc: string;
    icon: string;
    url: string;
    defaults: DefaultProps;
}

const PrototypeCard: React.FunctionComponent<EntryProps> = (props) => {
    // eslint-disable-next-line
    const { id, title, desc, icon, url, defaults } = props;

    const [open, setOpen] = useState(false);

    const storedToken = getTokenFromStorage();
    const accessToken = storedToken?.access_token;

    // Flagsmith Usage
    const flags = useFlags([id]); // Pulls all the flags, and their state (enabled/not enabled)
    const flagsmith = useFlagsmith(); // Pulls IFlagsmith class which is configured by FlagsmithProvider
    const analyticsDetail = getUserAnalyticsDetails(); // Gets the user details for identification
    flagsmith.identify(analyticsDetail.uuid, analyticsDetail as {}); // Enforces an identity check

    // If enabled, we'll pull the value as a JSON, with fallbacks defined if it is not enabled or fails
    const flagJson = flags[id].enabled ? flagsmith.getValue(id, { json: true, fallback: defaults }) : defaults;

    const showModal = () => {
        if (flagJson.enabled) {
            track('Prototype Launch: ' + title);

            setOpen(true);
        }
    };

    const hideModal = () => {
        setOpen(false);
    };

    return (
        <div>
            <Card
                key={`card-${title}`}
                type="inner"
                style={{ width: 300 }}
                cover={<img alt={title} src={process.env.PUBLIC_URL + icon} />}
                hoverable={true}
                onClick={showModal}
                hidden={flagJson.hidden}
            >
                <Meta title={title} description={desc} />
            </Card>
            <Modal
                key={`modal-${title}`}
                open={open}
                title={title}
                onCancel={hideModal}
                closeIcon={React.createElement(ShrinkOutlined)}
                footer={null}
                style={{ top: 0, margin: 0 }}
            >
                <iframe
                    loading={'lazy'}
                    src={url + '/?auth=' + accessToken}
                    title={title}
                    style={{ border: '2px solid #57b2b5' }}
                />
            </Modal>
        </div>
    );
};

export default PrototypeCard;
