import React from 'react';
import { Button, Result } from 'antd';

export type ErrorText = {
    title: string;
    description: string;
};

interface ErrorPageProps {
    errorText: ErrorText;
    buttonText: string;
    onClick: () => void;
}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = (props) => {
    const { errorText, buttonText, onClick } = props;
    return (
        <div className="error-page">
            <Result
                status="error"
                title={errorText.title}
                subTitle={errorText.description}
                extra={[
                    <Button type="primary" key="btn" onClick={onClick}>
                        {buttonText}
                    </Button>,
                ]}
            />
        </div>
    );
};

export default ErrorPage;
