// Implementation from common-fe
type TypeParams<T> = { [K in keyof T]?: string };

export const parseQuery = <T>(queryString: string, decodeURI: boolean = true): TypeParams<T> => {
    const query: TypeParams<T> = {};
    const pairs = (queryString.split('?').pop() ?? queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        const key = (decodeURI ? decodeURIComponent(pair[0]) : pair[0]) as keyof T;
        query[key] = decodeURI ? decodeURIComponent((pair[1] ?? '').replace(/\+/g, ' ')) : (pair[1] ?? '');
    }
    return query;
};

export const makeQueryPath = (
    path: string,
    query: { [key: string]: string | number | undefined },
    encode: boolean = true
): string => {
    const keys = Object.keys(query).filter((key) => query[key]);
    if (keys.length === 0) {
        return path;
    }
    return keys.reduce((newPath, key, i) => {
        const value = encode ? window.encodeURIComponent(query[key] ?? '') : query[key];
        return `${newPath}${key}=${value}${i < keys.length - 1 ? '&' : ''}`;
    }, `${path}?`);
};
