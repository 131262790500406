import config from '../config';
import {
    EAGLE_ID_API_V1,
    EAGLE_ID_AUTH,
    EAGLE_ID_LOGOUT,
    EAGLE_ID_OAUTH,
    EAGLE_ID_TOKEN,
    EAGLE_ID_VERIFY,
} from '../constants/urlPaths';

function getEagleIdOauthPath() {
    return `${config.EAGLE_ID_DOMAIN}${EAGLE_ID_API_V1}${EAGLE_ID_OAUTH}`;
}

export function getEagleIdAuthURL() {
    return `${getEagleIdOauthPath()}${EAGLE_ID_AUTH}`;
}

export function getEagleIdTokenURL() {
    return `${getEagleIdOauthPath()}${EAGLE_ID_TOKEN}`;
}

export function getEagleIdVerifyURL() {
    return `${getEagleIdOauthPath()}${EAGLE_ID_VERIFY}`;
}

export function getLogoutURL() {
    return `${getEagleIdOauthPath()}${EAGLE_ID_LOGOUT}`;
}
