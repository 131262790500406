import React, { useEffect, useState } from 'react';

import LandingPage from '../LandingPage';
import { logout, verifyToken } from '../../utils/authUtils';
import { useNavigate } from 'react-router-dom';
import * as routeUrls from '../Routes/routeUrls';
import { getTokenFromStorage } from '../../utils/storageUtils';
import Spinner from '../shared/Spinner';
import ErrorPage from '../Auth/ErrorPage';
import { ErrorText } from '../Auth/ErrorPage';
import { useFlagsmith } from 'flagsmith/react';

import { getUserAnalyticsDetails } from '../../utils/analyticsUtils';

const App: React.FunctionComponent = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const [errorText, setErrorText] = useState<undefined | ErrorText>(undefined);
    const handleError = (e: Error) => {
        setErrorText({
            title: 'An error occurred',
            description: e.toString(),
        });
    };

    const flagsmith = useFlagsmith();

    useEffect(() => {
        const storedToken = getTokenFromStorage();

        if (storedToken) {
            verifyToken(storedToken)
                .then((isValid) => {
                    if (isValid) {
                        setIsAuthenticated(true);

                        const analyticsDetail = getUserAnalyticsDetails();

                        flagsmith.identify(analyticsDetail.uuid, analyticsDetail as {});
                    } else {
                        logout();
                        navigate(routeUrls.AUTH);
                    }
                })
                .catch(handleError);
        } else {
            navigate(routeUrls.AUTH);
        }
    }, [flagsmith, navigate]);

    if (errorText) {
        return <ErrorPage errorText={errorText} buttonText={'Try again'} onClick={() => logout()} />;
    }

    return isAuthenticated ? <LandingPage /> : <Spinner tip={'Signing in...'} />;
};

export default App;
