/// <reference path="./global.d.ts" />

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routes from './components/Routes';
import reportWebVitals from './reportWebVitals';

import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <FlagsmithProvider
            options={{
                api: 'https://flags-labs.screeningeagle.com/api/v1/',
                eventSourceUrl: 'https://flags-labs.screeningeagle.com/api/v1/',
                environmentID:
                    process.env.NODE_ENV === 'production' ? 'ncsqVgFANKgZAZEB2Kaxe2' : '9vENpt8JFx6ZYtdhPzVNyE',
                enableAnalytics: true,
                cacheFlags: true,
            }}
            flagsmith={flagsmith}
        >
            <Routes />
        </FlagsmithProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
