import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as routeUrls from './routeUrls';

import App from '../App';
import Auth from '../Auth';
import NotFound from './NotFound';

const router = createBrowserRouter([
    {
        path: routeUrls.HOME,
        element: <App />,
    },
    {
        path: routeUrls.AUTH,
        element: <Auth />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);

const Routes: React.FunctionComponent = () => {
    return <RouterProvider router={router} />;
};

export default Routes;
