import React from 'react';
import type { MenuProps } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

export type MenuItemData = {
    icon: React.ComponentType;
    label: string;
};

const menuData: MenuItemData[] = [
    {
        icon: LogoutOutlined,
        label: 'Logout',
    },
];

export const menuItems: MenuProps['items'] = menuData.map((item) => ({
    key: item.label,
    icon: React.createElement(item.icon),
    label: item.label,
}));
